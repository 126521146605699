<template>
  <BaseModal
    size="3xl"
    :show="show"
    title="Fill in Prompt Variables"
    @close="$emit('close')"
  >
    <div>
      <div class="mb-5 leading-[2.25em]" v-html="form.content"></div>
    </div>
    <BaseButton @click.prevent="handleSubmit">
      {{ type === 'copy' ? 'Copy' : 'Fill' }}
    </BaseButton>
  </BaseModal>
</template>

<script setup lang="ts">
import { useClipboard } from '@vueuse/core'
import useNotifier from '~/composables/useNotifier'
import useFullScreenModal from '~/composables/useFullScreenModal'

const props = defineProps({
  show: {
    type: Boolean,
    default: false,
  },
  type: {
    type: String,
    default: 'copy',
  },
  initialValues: {
    type: Object,
    default: () => ({
      id: null,
      title: '',
      content: '',
      category: 'chatgpt',
    }),
  },
})

const emit = defineEmits(['close', 'submit'])
const form = ref<any>(props.initialValues)
const { notifySuccess } = useNotifier()
const { copy } = useClipboard()
const fullScreenModal = useFullScreenModal()

watch(
  () => props.initialValues,
  () => {
    form.value = { ...props.initialValues }
    handleVariables()
  }
)

const handleSubmit = () => {
  const content = handleVariableReplace()

  if (props.type === 'fill') {
    emit('submit', { content })
    return
  }

  copy(content)

  notifySuccess({ title: 'Copied!', text: 'Prompt copied to clipboard.' })

  emit('close')
}

const handleVariables = () => {
  var string = form.value.content || ''
  var regex = /\{\{(.*?)\}\}/g

  let idx = -1

  form.value.content = string.replace(regex, (match: string, text: string) => {
    idx++

    const dataIndex = `data-index="${idx}"`
    const dataMatch = `data-match="${match}"`
    const classes =
      'js-variable-input inline-block px-1.5 w-32 py-0.5 border shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md placeholder-gray-400'

    return `<input type="text" ${dataIndex} ${dataMatch} class="${classes}" placeholder="${text}" />`
  })
}

const handleVariableReplace = () => {
  var string = props.initialValues.content
  var regex = /\{\{(.*?)\}\}/g
  var matches = string.match(regex)

  matches.map((item, index) => {
    var element = document.querySelector(`[data-index="${index}"]`)
    var replacementValue = element?.value
    string = string.replace(new RegExp(`${item}`, 'g'), replacementValue)
  })

  return string
}
</script>
